<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <div class="d-flex align-items-center justify-content-between mt-1 mb-2 pb-50">
          <b-link class="brand-logo my-0">
            <logo />
            <h2 class="brand-text text-primary ml-1">
              {{ appName }}
            </h2>
          </b-link>
          <locale />
        </div>

        <b-card-text class="mb-2">
          {{ $t('loginTitle') }}
        </b-card-text>

        <validation-observer ref="vo">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="onSubmitForm"
          >
            <b-form-group
              label-for="email"
              :label="$t('Email Address')"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length ? false : null"
                  placeholder="john@example.com"
                  :disabled="isLoading"
                />
                <small class="text-danger">
                  <template v-if="failedRules.required">{{ $t('validationErrorRequiredEmail') }}</template>
                  <template v-else-if="failedRules.email">{{ $t('validationErrorEmail') }}</template>
                  <template v-else>{{ errors[0] }}</template>
                </small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">{{ $t('Password') }}</label>
                <b-link :to="{ name: 'forgot-password', params: { userEmail } }">
                  <small>{{ $t('Forgot password?') }}</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors, failedRules }"
                name="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length ? false : null"
                    name="login-password"
                    placeholder="············"
                    :disabled="isLoading"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">
                  <template v-if="failedRules.required">{{ $t('validationErrorRequiredPassword') }}</template>
                  <template v-else>{{ errors[0] }}</template>
                </small>
              </validation-provider>
            </b-form-group>
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="isLoading"
            >
              {{ $t('Sign in') }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <i18n path="loginMessage">
            <template v-slot:slot>
              <b-link :to="{ name: 'register' }">
                <span>{{ $t('Create an account') }}</span>
              </b-link>
            </template>
          </i18n>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapGetters, mapActions } from 'vuex'
import { $themeConfig } from '@themeConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import LOGIN from '@/gql/mutation/user/login.gql'
import GET_USER_DATA from '@/gql/query/getUserData.gql'
import CONFIRM_REGISTRATION from '@/gql/mutation/user/confirmRegistration.gql'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    Logo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    Locale,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [
    togglePasswordVisibility,
  ],
  data() {
    return {
      isLoading: false,
      appName: $themeConfig.app.appName,
      userEmail: '',
      password: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      isUserFirstLogin: 'user/isUserFirstLogin',
      invitationCode: 'main/invitationCode',
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async created() {
    const { invitationCode } = this.$route.params
    if (invitationCode) {
      this.setInvitationCode(invitationCode)
    }

    const { confirmationCode } = this.$route.query
    if (confirmationCode) {
      try {
        await this.$apollo.mutate({
          mutation: CONFIRM_REGISTRATION,
          variables: {
            confirmationCode,
          },
        })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Success'),
            text: this.$t('notificationSuccessEmailConfirmed'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    }
  },
  methods: {
    ...mapActions({
      setUser: 'user/setUser',
      setIsUserFirstLogin: 'user/setIsUserFirstLogin',
      setInvitationCode: 'main/setInvitationCode',
    }),
    onSubmitForm() {
      this.$refs.vo.validate().then(async isValid => {
        if (!isValid) {
          return
        }

        try {
          this.isLoading = true

          const responseLogin = await this.$apollo.mutate({
            mutation: LOGIN,
            variables: {
              email: this.userEmail,
              password: this.password,
            },
          })
          useJwt.setToken(responseLogin.data.login.shortAccessToken)

          const responseGetUserData = await this.$apollo.query({
            query: GET_USER_DATA,
          })
          this.setUser(responseGetUserData.data.getUserData)

          if (this.invitationCode) {
            await this.$router.push({ name: 'invitation', query: { invitationCode: this.invitationCode } })
            this.setInvitationCode('')
          } else if (this.isUserFirstLogin) {
            this.setIsUserFirstLogin(false)
            await this.$router.push({ name: 'settings' })
          } else {
            await this.$router.push({ name: 'dashboard' })
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Error'),
              text: Array.isArray(error.graphQLErrors[0].message)
                ? error.graphQLErrors[0].message[0]
                : error.graphQLErrors[0].message,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      })
    },
  },
}
</script>

<style lang="sass">
  @import '@core/scss/vue/pages/page-auth.scss'
</style>
